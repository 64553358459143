<app-section-slider4 [sliderData]="homeModelData?.slider"></app-section-slider4>

<app-section-main-news *ngIf="homeModelData?.news.length>=1"  [newsData]="homeModelData?.news"></app-section-main-news>
<app-section-projects2   [data3]="social" [categoryData]="homeModelData?.categories" [eventData]="homeModelData?.event" [noticeData]="homeModelData?.notice" [noticeCategoryData]="homeModelData?.noticeCategories" overlay="1"></app-section-projects2>
<app-section-services9  [activityHistoryData]="avesisHistory" [latestPublicationData]="avesisLatest"></app-section-services9>


<div class="container-fluid my-4 p-t60">
  <div class=" wt-small-separator site-text-primary"> <h3 class="text-left">E-Hizmetler</h3></div>
  <div class="divider"></div>
  <div class="row">
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="https://turkiye.gov.tr/ankara-universitesi" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                    <i class="fa fa-columns card-icon"></i>

                      <h5 class="card-title">E-Devlet Hizmetleri</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="https://ekampus.ankara.edu.tr/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                      <i class="fa fa-university card-icon"></i>
                      <h5 class="card-title">e-Kampus</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="http://ebeyas.ankara.edu.tr/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                    <i class="fa fa-copy card-icon"></i>
                      <h5 class="card-title">Elektronik Belge Yönetimi ve Arşiv Sistemi (e-BEYAS)</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="https://acikders.ankara.edu.tr/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                      <i class="fa fa-book card-icon"></i>
                      <h5 class="card-title">Açık Ders Malzeme Portalı</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="https://dspace.ankara.edu.tr/home" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                      <i class="fa fa-archive card-icon"></i>
                      <h5 class="card-title">Akademik Arşiv Sistemi</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="http://kutuphane.ankara.edu.tr/e-kutuphane/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                    <i class="fa fa-book card-icon"></i>
                      <h5 class="card-title">E-Kütüphane</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="http://ekders.ankara.edu.tr/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                      <i class="fa fa-calculator card-icon"></i>
                      <h5 class="card-title">Ek Ders Yazılımı</h5>
                  </div>
              </div>
          </a>
      </div>
      <div class="col-md-3 col-6 mb-4">
          <a  target="_blank"href="https://avesis.ankara.edu.tr/" class="text-decoration-none">
              <div class="card card-custom">
                  <div class="card-body">
                      <i class="fa fa-database card-icon"></i>
                      <h5 class="card-title">Akademik Veri Yönetim Sistemi</h5>
                  </div>
              </div>
          </a>
      </div>
  </div>
</div>
<app-section-video1 *ngIf="this.currentLang!='en-EN' " [data]="video"></app-section-video1>

