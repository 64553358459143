import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDetailListBase } from 'src/app/contracts/base/detail-base';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { CategoryEntity } from 'src/app/contracts/cms/category-entity';

import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';

const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
@Component({
  selector: 'app-section-post-list',
  templateUrl: './section-post-list.component.html',
  styleUrls: ['./section-post-list.component.css'],
})
export class SectionPostListComponent implements OnInit {

  postType: string | any;
  newsData: IDetailListBase<NewsEntity[]> | any;
  method: string | any;
  page = 1;
  controlPage = 0;
  currentLang = 'tr-TR';
  getlang = 'primary';
  onceki = '';
  sonraki = '';
  breadcumb: Breadcrumb[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private seoService: SeoService,
    private storageService: StorageService
  ) {
    this.postType = this.activatedRoute.snapshot.params['type'];

    if (this.activatedRoute.snapshot.params['page']) {
      this.page = this.activatedRoute.snapshot.params['page'];
      this.controlPage = this.activatedRoute.snapshot.params['page'];
    }
  }
  pagesArray: number[] = [];

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;

      await this.translationServices();
    });
  }

  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    this.newsData = [];

    this.getNews();

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);

    this.updateMetaTags();
  }

  updateMetaTags(): void {
    const title =
      this.currentLang == 'tr-TR'
        ? 'HaberLer | Ankara Üniversitesi'
        : 'News | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'haberler', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/haberler/' + this.page;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      'Haberler',
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );



    this.breadcumb.push({
      label: "Haberler",
      url: '/kategori/'+canonicalUrl,
    });
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  async getNews() {
    const promiseData: IDetailListBase<NewsEntity[]> | any =
      await this.httpClientService.getPageable(
        this.page - 1,
        20,
        'CMS',
        'GetMainNews',
        '',
        1,
        this.currentLang
      );

    this.newsData = promiseData;
    this.pagesArray = Array.from(
      { length: this.newsData.pages },
      (_, index) => index + 1
    );

    this.limitPages(20);
  }
  limitPages(limit: number) {
    this.pagesArray = this.pagesArray.slice(0, limit);
  }
  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  // Ay isimlerini döndüren fonksiyon
  ayIsimAl(ayIndex: number): string {
    const lang = this.currentLang;
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }
}
