import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { UnitEntity } from 'src/app/contracts/cms/unit-entity';
import { RequestParameters } from 'src/app/services/http-client-services';
import {
  LangService,
  _languageDefaultCode,
} from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';

import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-unit-grid-list',
  templateUrl: './unit-grid-list.component.html',
  styleUrls: ['./unit-grid-list.component.css'],
})
export class UnitGridListComponent implements OnInit {
  param: string | any;
  units: UnitEntity[] | any;
  title = '';
  currentLang = 'tr-TR';
  breadcumb: Breadcrumb[] = [
    {
      label: 'Birimler',
      url: '/dizin/birimler',
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private translationService: TranslationService,
    private langService: LangService,
    private storageService: StorageService,
    private seoService: SeoService,
  ) {
    this.param = this.activatedRoute.snapshot.params['param'];
  }

  async ngOnInit(): Promise<void> {
    await this.langServices();
  }

  async langServices() {
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.breadcumb = [];
      this.breadcumb = [
        {
          label: 'Birimler',
          url: '/dizin/birimler',
        },
      ];
      this.translationServices();
      this.getUnits(this.param);
    });
  }
  jsonData: any[] =[];

  updateMetaTags(titlex:string="",ulr:string=''): void {
    const title =
      this.currentLang == 'tr-TR'
        ?  titlex.length>=2 ? titlex + ' | Ankara Üniversitesi': 'Programlar | Ankara Üniversitesi'
        :titlex.length>=2 ? titlex + ' |Ankara University': 'Programs | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'Birimler', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/dizin/'+ ulr.toLowerCase();

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex !=null ? titlex : "Birimler",
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  onExportChange(event: any): void {
    const selectedOption = event.target.value;
    if (selectedOption === 'pdf') {
      this.exportToPDF();
    } else if (selectedOption === 'excel') {
      this.exportToExcel();
    }
  }

 async  sortData( order: any) {

    var val = order.target.value;
    return this.units.sort((a:any, b:any) => {
      if (val === 'A-Z') {
        return a.Baslik.localeCompare(b.Baslik);
      } else {
        return b.Baslik.localeCompare(a.Baslik);
      }
    });
  }

  exportToPDF(): void {
    const doc = new jsPDF();
    const col = ['Başlık'];
    const rows: any[] = [];

    this.units.forEach((item:any) => {
      const temp = [item.Baslik, item.Deger];
      rows.push(temp);
    });

    (doc as any).autoTable({
      head: [col],
      body: rows,
    });
    doc.save(this.param + '.pdf');
  }

  exportToExcel(): void {
   // Sadece 'Baslik' alanını içeren verileri seçmek
   const dataToExport = this.units.map((item:any) => ({ Baslik: item.Baslik }));

   // Excel çalışma sayfası oluşturma
   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, {
     header: ['Baslik'], // Sütun başlıkları
     skipHeader: false // Başlıkları dahil et
   });

   // Başlık adını değiştirmek
   ws['!cols'] = [{ wch: 400 }]; // Sütun genişliğini ayarla (isteğe bağlı)
   ws['A1'].v = this.title; // Sütun başlığını değiştir

   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, this.title);

   // Excel dosyasını kaydetme
   XLSX.writeFile(wb, this.param+'.xlsx');

  }


  translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    var getparam = '';
    if (this.param == 'Fakulteler') {
      getparam = 'fakulteler';


    }
    if (this.param == 'enstituler') {
      getparam = 'enstituler';

    }
    if (this.param == 'konservatuvar') {
      getparam = 'konservatuvar';

    }
    if (this.param == 'myo') {
      getparam = 'myo';

    }
    if (this.param == 'yuksekokullari') {
      getparam = 'yuksekokul';

    }
    if (this.param == 'idari') {
      getparam = 'idaribirimler';

    }
    if (this.param == 'koordinatorlukler') {
      getparam = 'koordinatorlukler';

    }
    if (this.param == 'merkezler') {
      getparam = 'merkezler';

    }
    if (this.param == 'hastaneler') {
      getparam = 'hastaneler';

    }

    this.title = this.translationService.getTranslation(getparam, lang);

    this.breadcumb.push({
      label: this.title,
      url: 'dizin/' + this.param,
    });


    this.updateMetaTags(this.title,getparam)
  }

  async getUnits(param: string) {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
       environment.baseUrl+ '/Kapi/GetUnits?param=' +
        param ,
    };

    const promise: UnitEntity[] | any = await this.httpClientService.getx(
      requestParameter
    );

    this.units = promise.data;
  }
}
