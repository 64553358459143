import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDetailListBase } from 'src/app/contracts/base/detail-base';
import { CategoryEntity } from 'src/app/contracts/cms/category-entity';

import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
import { Location } from '@angular/common';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
interface UrlData {
  type: string;
  getMethod: string;
}

const urlData: UrlData[] | any[] = [
  { type: 'haberler', getMethod: 'News' },
  { type: 'etkinlikler', getMethod: 'Events' },
  { type: 'duyurular', getMethod: 'Notices' },
  { type: 'kurumsal', getMethod: 'Pages' },
];

@Component({
  selector: 'app-section-page-category-list',
  templateUrl: './section-page-category-list.component.html',
  styleUrls: ['./section-page-category-list.component.css'],
})
export class SectionPageCategoryListComponent implements OnInit {
  postType: string | any;
  breadcumb: Breadcrumb[] = [];
  categorySlug: string | any;
currentLang:string | any;
onceki="";
sonraki="";
  newsData: IDetailListBase<NewsEntity[]> | any;
  method: string | any;
  page = 1;
  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private seoService: SeoService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private location: Location
  ) {



    this.categorySlug = this.activatedRoute.snapshot.params['category'];

    /* if(!this.categorySlug){
      const fullPath = this.location.path();
      const segments = fullPath.split('/');

      console.log(fullPath)
      console.log(segments)
      // Dizideki 1. elemanı almak
      if (segments.length > 1) {
        this.categorySlug = segments[2];
      }

    } */
    if (this.activatedRoute.snapshot.params['page']) {
      this.page = this.activatedRoute.snapshot.params['page'];
    }
  }
  pagesArray: number[] = [];

  async ngOnInit() {

    if (!this.page) {
      this.page = 0;
    }
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });



  }

  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    await this.getNews();

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);
  }
  async methodFind(value: string) {
    const foundElement = urlData.find((element: UrlData) => {
      return element.type === value;
    });


    return foundElement.getMethod;
  }

  updateMetaTags(titlex: string = '', newsimage: string = ''): void {
    const title =
      this.currentLang == 'tr-TR'
        ? titlex + ' | Ankara Üniversitesi'
        : titlex + ' | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'haberler', titlex];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl = newsimage
      ? newsimage
      : 'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/kurumsal/'+ this.categorySlug+'/' + this.page;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex,
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );
    this.breadcumb = [
      {
        label: titlex,
        url: '/krumsal/programlar',
      },
    ];
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  routerw(val: string) {
    this.newsData = null;

    this.page = 1;
    this.categorySlug = val;
    this.router.navigate(['/kategori', this.postType, val]);
    this.getNews();
  }

  async getNews() {
    const promiseData: IDetailListBase<NewsEntity[]> | any =
      await this.httpClientService.getPageable(
        this.page - 1,
        20,
        'CMS',
        'GetMainPages',
        '&categorySlug=' + this.categorySlug,
        1,
        'tr-TR'
      );

    this.newsData = promiseData;
    this.pagesArray = Array.from(
      { length: this.newsData.pages },
      (_, index) => index + 1
    );

    this.updateMetaTags(this.categorySlug,'');
}
  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    switch (ayIndex) {
      case 0:
        return 'Ocak';
      case 1:
        return 'Şubat';
      case 2:
        return 'Mart';
      case 3:
        return 'Nisan';
      case 4:
        return 'Mayıs';
      case 5:
        return 'Haziran';
      case 6:
        return 'Temmuz';
      case 7:
        return 'Ağustos';
      case 8:
        return 'Eylül';
      case 9:
        return 'Ekim';
      case 10:
        return 'Kasım';
      case 11:
        return 'Aralık';
      default:
        return '';
    }
  }
}
