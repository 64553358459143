import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import {
  LangService,
  _languageDefaultCode,
} from 'src/app/services/lang-service';
import { TranslationService } from 'src/app/services/translation.services';

declare var jQuery: any;
declare var services_slider: any;
@Component({
  selector: 'app-section-main-news',
  templateUrl: './section-main-news.component.html',
  styleUrls: ['./section-main-news.component.css'],
})
export class SectionMainNewsComponent implements OnInit, OnChanges {
  @Input() newsData: NewsEntity[] | any;
  data: NewsEntity | any;

  allNewsButton = '';
  allEventButton = '';
  allNoticeButton = '';
content="";
  constructor(private translationService: TranslationService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newsData'].currentValue) {
      this.data = changes['newsData'].currentValue;


    }

    if (this.data != null) {
      this.createSlider();
      this.content = this.truncateText( this.data[0].icerik.replace('<h1>&nbsp;</h1>', ''),200)
    }
  }
  stripHtmlTags(htmlContent: string): string {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    return doc.body.textContent || '';
  }
  ngOnInit(): void {
    this.translationServices();
  }
    truncateText(text: string, maxLength: number):string {

    if (text.length > maxLength) {
      return  text.replace('<h1>&nbsp;</h1>', '').substring(0, maxLength) + '[...]';
    } else {
      return text;
    }
  }
  translationServices() {
    const lang: 'en' | 'tr' = _languageDefaultCode as 'en' | 'tr';
    this.allNewsButton=  this.translationService.getTranslation('allNewsButton', lang);
  }

  createSlider() {
    (function ($) {
      setTimeout(function () {
        services_slider();
      }, 300);
    })(jQuery);
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }

  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }

  ayIsimAl(ayIndex: number): string {
    switch (ayIndex) {
      case 0:
        return 'Ocak';
      case 1:
        return 'Şubat';
      case 2:
        return 'Mart';
      case 3:
        return 'Nisan';
      case 4:
        return 'Mayıs';
      case 5:
        return 'Haziran';
      case 6:
        return 'Temmuz';
      case 7:
        return 'Ağustos';
      case 8:
        return 'Eylül';
      case 9:
        return 'Ekim';
      case 10:
        return 'Kasım';
      case 11:
        return 'Aralık';
      default:
        return '';
    }
  }
}
