import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupEntity } from 'src/app/contracts/cms/popup-entity';
import { SliderEntity } from 'src/app/contracts/cms/slider-entity';
import { LangService } from 'src/app/services/lang-service';
import { ResponsiveService } from 'src/app/services/responsive-service';
import { TranslationService } from 'src/app/services/translation.services';

declare var jQuery: any;
declare var load_rev_slider_4: any;

@Component({
  selector: 'app-section-slider4',
  templateUrl: './section-slider4.component.html',
  styleUrls: ['./section-slider4.component.css'],
})
export class SectionSlider4Component
  implements AfterViewInit, OnInit, OnChanges,OnDestroy
{
  @Input() sliderData: SliderEntity[] | any;


  @ViewChild('sliderContainer') sliderContainer: any; // ViewChild ile slider bileşenine erişim
  sliders: SliderEntity[] | any;

  popup:PopupEntity[]|any;

  constructor(private langService: LangService,
    private responsiveService: ResponsiveService
  ) {}
  private langChangeSubscription: Subscription | any;
  ngOnInit(): void {

    this.setupMenu();
    this.langChangeSubscription = this.langService.currentLangCode$.subscribe(
      (langCode) => {
        SectionSlider4Component
      }
    );
  }
  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private setupMenu() {
    if (this.responsiveService.isMobileScreen()) {
      this.isMobile = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sliderData'] && changes['sliderData'].currentValue) {
      this.sliders = changes['sliderData'].currentValue;
this.createSlider();
    }



  }

isMobile:boolean=false;

  createSlider() {

    (function ($) {
      setTimeout(function () {
        load_rev_slider_4();
      }, 400);
    })(jQuery);
  }

  ngAfterViewInit() {}

  modalStatus:boolean=true;
  closeModal() {
    this.modalStatus=false;
      jQuery('#myModal1').modal('hide');

  }


}
