<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner
      title="Birimler"
      [breadcrumbs]="breadcumb"
    ></app-section-inner-banner>

    <div class="section-full p-t80 p-b50 bg-white" *ngIf="units != null">
      <div class="container">

        <div class="row d-flex justify-content-between">

          <div class="col-md-7">

            <h2>{{ title }}</h2>
          </div>
          <div class="col-md-2">

            <select
              id="sort-options"
              class="form-control"
              (change)="sortData($event)"
            >
              <option value="A-Z">A-Z Sıralama</option>
              <option value="Z-A">Z-A Sıralama</option>
            </select>
          </div>
          <div class="col-md-3">

            <select
              id="export-options"
              class="form-control"
              (change)="onExportChange($event)"
            >
              <option value="">Dışa Aktarma Seçenekleri:</option>

              <option value="excel">Excel Olarak İndir</option>
            </select>
          </div>
        </div>

        <!-- BLOG SECTION START -->
        <div class="section-content">

          <div class="row d-flex justify-content-center">
            <!--Block one-->
            <div
              class="col-lg-4 col-md-6 col-sm-12"
              *ngFor="let object of units"
            >
              <div class="blog-post date-style-2">
                <div class="wt-post-media wt-img-effect zoom-slow">
                  <a routerLink="{{ object.slug }}"
                    ><img
                      *ngIf="object.Kapak_Gorseli != null"
                      class="vh-45"
                      src="https://dizin.ankara.edu.tr/assets/{{
                        object.Kapak_Gorseli.split('?')[0]
                      }}"
                      alt=""
                    />
                    <img
                      *ngIf="object.Kapak_Gorseli == null"
                      class="vh-45"
                      src="https://www.ankara.edu.tr/wp-content/themes/75yil/img/placeholder.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div class="wt-post-info bg-white p-t5">
                  <div class="wt-post-meta">
                    <ul>
                      <li class="post-category">
                        <span>
                          <a
                            target="_blank"
                            href="{{ object.Web_Sitesi }}"
                            class="site-button-link white"
                            >Website</a
                          ></span
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="wt-post-title">
                    <h4 class="post-title">
                      <a
                        routerLink="{{ object.slug }}"
                        class="site-text-secondry"
                        >{{ object.Baslik }}</a
                      >
                    </h4>
                  </div>
                  <!--  <div class="wt-post-readmore ">
                                      <a routerLink="{{object.slug}}" class="site-button-link black">Website</a>
                                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <ul class="pagination m-b0 p-b0" *ngIf="units.length >= 20">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);">Next</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
