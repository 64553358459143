<div
  class="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section"
  *ngIf="data.length >= 1"
  style="background-image:url('{{ data[0].manset }}')"
>
  <div class="overlay-main site-bg-secondry opacity-09"></div>

  <div class="section-content services-section-content">
    <div class="row  news-section">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <div class="services-section-content-left">
          <!-- TITLE START-->
          <div class="left wt-small-separator-outer text-white">
            <a href="haber/{{ data[0].url }}">
              <div class="wt-small-separator site-text-primary">
                <div class="sep-leaf-left"></div>
                <div>{{ data[0].baslik }}</div>
                <div class="sep-leaf-right"></div>
              </div>

              <p [innerHTML]="content"></p>
              <div class="item">
                <div class="projects-two-info bg-white">
                  <div class="wt-media img-reflection">
                    <img src="{{ data[0].manset }}" alt="" />
                  </div>

                  <div class="wt-post-meta">
                    <ul>
                      <li class="post-category">
                        <span>{{ data[0].category.name }}</span>
                      </li>
                      <li class="post-date">
                        {{ transform(data[0].updatedDate!=null ? data[0].updatedDate: data[0].createdDate) }}
                        {{ ayYaz(data[0].updatedDate!=null ? data[0].updatedDate: data[0].createdDate) }}
                        {{ transformYil(data[0].updatedDate!=null ? data[0].updatedDate: data[0].createdDate) }}

                        {{data[0].updatedDate!=null ? "(Güncellendi)":""}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <!-- TITLE END-->
        </div>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-12">
        <div class="services-section-content-right">
          <a
            style="left: 64vh; position: absolute; top: -0px"
            routerLink="/kategori/haberler/1"
            class="site-button site-btn-effect d-none d-md-block news-btn"
            >{{ allNewsButton }}</a
          >
          <div class="owl-carousel services-slider owl-btn-vertical-center">
            <div class="item" *ngFor="let object of data">
              <a href="haber/{{ object.url }}">
                <div class="wt-box service-box-1 bg-white">
                  <div
                    class="service-box-title title-style-2 site-text-secondry"
                  >
                    <span class="s-title-one">{{ object.baslik }}</span>
                    <!-- <span  class="s-title-two">Gas Engineering</span>      -->
                  </div>
                  <div class="service-box-content">
                    <div class="projects-two-info bg-white">
                      <div class="wt-media img-reflection">
                        <img src="{{ object.manset }}" alt="" />
                      </div>

                      <p class="p-h" >{{truncateText( stripHtmlTags(object.icerik),200)}}</p>
                    </div>
                  </div>

                  <div class="wt-icon-box-wraper">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-category">
                          <i class="fa fa-sitemap"></i>
                          <span>{{ object.category.name }}</span>
                        </li>
                        <li class="post-date">
                          <i class="fa fa-calendar"></i>
                          {{ transform(object.createdDate) }}
                          {{ ayYaz(object.createdDate) }}
                          {{ transformYil(object.createdDate) }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <a
      style=" top:10px"
      routerLink="/kategori/haberler/1"
      class="site-button site-btn-effect text-center d-md-none d-block news-btn-mobile"
      >{{ allNewsButton }}</a
      >
      </div>


    </div>

  </div>

</div>

