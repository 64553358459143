<div class="box-style1-outer" *ngIf="data != null">
  <div class="row d-flex justify-content-center no-gutters">
    <div
      class="col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6"
      *ngFor="let object of data?.childs"
    >
      <div class="box-style1">
        <div class="wt-box">
          <div class="box-style1-title title-style-2">
            <a href="{{ object.slug }}"
              ><div class="wt-icon-box-xld">
                <span class="icon-cell site-text-primary"
                  ><!-- <img src="{{object.image}}" alt=""> -->
                  <span class="o-title-one">{{ object.name }} </span></span
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
      <div class="box-style1">
        <div class="wt-box">
          <div class="box-style1-title title-style-2">
            <a data-toggle="modal" data-target="#exampleModalXl"
              ><div class="wt-icon-box-xld">
                <span class="icon-cell site-text-primary"
                  ><!-- <img src="{{object.image}}" alt=""> -->

                  <span class="o-title-one">
                    {{
                      currentLang == "tr-TR"
                        ? "Çözüm Merkezi"
                        : "Student Solution Center"
                    }}
                  </span></span
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
