import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CategoryEntity } from 'src/app/contracts/cms/category-entity';
import { EventEntity } from 'src/app/contracts/cms/event-entity';
import { NoticeEntity } from 'src/app/contracts/cms/notice-entitty';
import { StorageService } from 'src/app/services/local-storage-serves';
import { TranslationService } from 'src/app/services/translation.services';


declare var jQuery: any;
declare var event_carousel: any;
declare var notice_carousel: any;

const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-section-projects2',
  templateUrl: './section-projects2.component.html',
  styleUrls: ['./section-projects2.component.css'],
})
export class SectionProjects2Component implements OnInit, OnChanges {
  @Input() data: any;
  @Input() data2: any;
  @Input() data3: any;
  @Input() overlay: any;

  @Input() categoryData: CategoryEntity[] | any;
  categories: CategoryEntity[] | any;

  @Input() noticeCategoryData: CategoryEntity[] | any;
  noticeCategories: CategoryEntity[] | any;

  @Input() eventData: EventEntity[] = [];
  events: EventEntity[] | any;
  filteredEvents: EventEntity[] | any;

  @Input() noticeData: NoticeEntity[] | any;
  notices: NoticeEntity[] = [];
  filteredNotices: NoticeEntity[] = []; // Yeni bir dizi ekledik

  allButton = '';
  title = '';
  title2 = '';
  all = '';

  getBackgroundImage(imageUrl: string): string {
    const defaultImage = 'https://ubys.ankara.edu.tr/Resources/Cms/10-07-2024/10-07-2024-14-15-07-event.jpeg';
    return `url(${imageUrl || defaultImage})`;
  }

  constructor(private translationService: TranslationService, private storageService: StorageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryData']) {
      this.categories = changes['categoryData'].currentValue;
    }
    if (changes['eventData']) {
      this.events = changes['eventData'].currentValue;
      this.filteredEvents = this.events;
      this.createSlider2(this.filteredEvents.length);
    }
    if (changes['noticeData']) {
      this.notices = changes['noticeData'].currentValue;
      this.filteredNotices = this.notices.filter((notice: any) => notice.categoryId == '2'); // Başlangıçta categoryId 2 olanları göster
      this.createSlider();
    }
    if (changes['noticeCategoryData']) {
      this.noticeCategories = changes['noticeCategoryData'].currentValue;
    }
  }

  createSlider() {
    (function ($) {
      const $carousel = $('.notice-carousel');
      if ($carousel.data('owl.carousel')) {
        $carousel.owlCarousel('destroy');
      }
      setTimeout(function () {
        notice_carousel();
      }, 300);
    })(jQuery);
  }

  createSlider2(adet: number) {
    (function ($) {
      const $carousel = $('.event-carousel');
      if ($carousel.data('owl.carousel')) {
        $carousel.owlCarousel('destroy');
      }
      setTimeout(function () {
        event_carousel();
        if (adet <= 5) {
          $carousel.owlCarousel({
            loop: false, // Loop özelliği false olarak ayarlandı
            // Diğer ayarlar buraya gelecek
          });
        }
      }, 300);
    })(jQuery);
  }

  ngOnInit(): void {
    this.translationServices();
  }

  convertToDate(date: any) {
    return new Date(date);
  }

  translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    this.allButton = this.translationService.getTranslation('tumu', lang);
    this.all = this.translationService.getTranslation('tum', lang);
    this.title = this.translationService.getTranslation('events', lang);
    this.title2 = this.translationService.getTranslation('duyurular', lang);
  }

  truncateText(text: string, maxLength: number): string {
    console.log(text);
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '[...]';
    } else {
      return text;
    }
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');
    var newTarih = new Date(splitDate[0]);
    return splitDate[0].split('-')[2];
  }

  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }

  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');
    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    const lang = this.storageService.getData('siteLanguage');
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }

  replaceThumb(thumb: any, platform: any) {
    var img = 'https://upload.wikimedia.org/wikipedia/tr/5/5f/Ankara_%C3%9Cniversitesi_logosu.png?20200406212437';
    return img;
  }

  filterEvents(categoryId: string): void {
    if (categoryId === '*') {
      this.filteredEvents = this.events;
    } else {
      this.filteredEvents = this.events.filter((event: any) => event.category.id === categoryId);
    }
    this.createSlider2(this.filteredEvents.length);
  }

  filterNotices(categoryId: string): void {

    console.log(categoryId)
    if (!this.notices) {
      console.error('Notices data is not available.');
      return;
    }
    if (categoryId == '*') {
      this.filteredNotices = this.notices;
    } else {
      this.filteredNotices = this.notices.filter((notice: any) => notice.categoryId == Number(categoryId));
    }


    console.log(this.filteredNotices)
    this.createSlider(); // Noticeler için slider'ı tekrar oluştur
  }
}
