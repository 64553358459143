import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute,Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs';
import { ItemDetailModel } from 'src/app/contracts/base/news-detail-model';
import { CMSDataEntity } from 'src/app/contracts/cms/cms-data-entity';
import { Protocol } from 'src/app/contracts/cms/protocol-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { TranslationService } from 'src/app/services/translation.services';
interface UrlData {
  type: string;
  getMethod: string;
}
const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const urlData: UrlData[] | any[] = [
  { type: 'haber', getMethod: 'NewsSlug' },
  { type: 'etkinlik', getMethod: 'EventsSlug' },
  { type: 'duyuru', getMethod: 'NoticesSlug' },
  { type: 'tanitim', getMethod: 'PagesSlug' },
];
@Component({
  selector: 'app-section-search-detail',
  templateUrl: './section-search-detail.component.html',
  styleUrls: ['./section-search-detail.component.css'],
})
export class SectionSearchDetailComponent implements OnInit {




  method: string | any;

  slug: string | any;

  items: CMSDataEntity | any;

  currentLang = 'tr-TR';
  getlang = 'primary';
  isRehber: boolean = false;
  onceki = '';
  sonraki = '';
  @Input() data: any;
  safeContent: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.slug = this.activatedRoute.snapshot.params['aranan'];
  }
  controlAday: boolean = false;
  ngOnInit(): void {
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.controlAday = event.urlAfterRedirects.startsWith('/aday-ogrenci');
        this.isRehber = event.urlAfterRedirects.startsWith('/rehber');

      });

    // İlk URL'yi kontrol etmek için
    this.controlAday = this.router.url.startsWith('/aday-ogrenci');
    this.isRehber = this.router.url.startsWith('/rehber');


    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });
  }






  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    if (!this.isRehber) {
      await this.getNews();
    }

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);
  }
  async getNews() {
    const promiseData: CMSDataEntity | any = await this.httpClientService.get(
      'CMS',
      'GetMainCmsSlug',
      '',
      1,
      this.currentLang,
      '&Slug=' + this.slug
    );
    const unwantedString = '<h1>&nbsp;</h1>';
    this.items = promiseData.item;
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(
      this.items.icerik.replace(unwantedString, '')
    );
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    const lang = this.currentLang;
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }
}
