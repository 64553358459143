import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ActivityHistoryEntity,
  ConferencePaper,
} from 'src/app/contracts/cms/avesis-entities';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { TranslationService } from 'src/app/services/translation.services';
const turkishMap: { [key: string]: string } = {
  ç: 'c',
  ğ: 'g',
  ı: 'i',
  ö: 'o',
  ş: 's',
  ü: 'u',
  Ç: 'C',
  Ğ: 'G',
  İ: 'I',
  Ö: 'O',
  Ş: 'S',
  Ü: 'U',
};
declare var jQuery: any;
declare var testimonial_11_content: any;

@Component({
  selector: 'app-section-testimonials6',
  templateUrl: './section-testimonials6.component.html',
  styleUrls: ['./section-testimonials6.component.css'],
})
export class SectionTestimonials6Component implements OnInit, OnChanges {
  title: string | any;
  currentLang = 'tr-TR';
  getlang = 'primary';
  @Input() data: any;
  @Input() activity: ConferencePaper[] | any;
  activ: ConferencePaper[] | any;
  public isExpanded: boolean = false;
  constructor(
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activity']) {
      this.activ = changes['activity'].currentValue;

      console.log(this.activ)
    }

    if (this.activ != null) {
      this.createCarousel();
    }
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
  createCarousel() {
    (function ($) {
      setTimeout(function () {
        testimonial_11_content();
      }, 200);
    })(jQuery);
  }
  async ngOnInit(): Promise<void> {
    await this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.getlang = langCode === 'tr-TR' ? 'primary' : 'secondary';
      this.translationServices();
    });
  }

  songullememler = '';
  yazarlar = '';

  translationServices() {
    const lang: 'en' | 'tr' =this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    this.songullememler = this.translationService.getTranslation(
      'songuncellemer',
      lang
    );
    this.yazarlar = this.translationService.getTranslation('yazarlar', lang);
  }
  slugify(text: string): string {
    return text
      .toString()
      .split('')
      .map((char) => turkishMap[char] || char)
      .join('')
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
}
