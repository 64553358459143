import { HttpErrorResponse } from "@angular/common/http";

import { Injectable } from '@angular/core';
import { HttpClientService } from "../services/http-client-services";
import { CustomToastrService, ToastrMessageType, ToastrPosition } from "../base/services/custom-toast-service";


@Injectable({
  providedIn: 'root'
})
export class ErrorHandler{
  constructor(
    public alertService: CustomToastrService
  ) {}

  public  getServerErrorMessage(error: HttpErrorResponse): any {
    console.log(error)
    switch (error.status) {


      case 400: {
        return this.alertService.message(error.error.detail, 'Başarısız', {

          messageType: ToastrMessageType.Error,
          position: ToastrPosition.TopFullWidth,
        });
      }
      case 403: {
        return this.alertService.message(error.error.detail, 'Başarısız', {
          messageType: ToastrMessageType.Error,
          position: ToastrPosition.TopRight,
        });
      }
      case 500: {
        return this.alertService.message(error.error.detail, 'Başarısız', {
          messageType: ToastrMessageType.Error,
          position: ToastrPosition.TopRight,
        });
      }
      default: {
        return this.alertService.message(error.error.detail, 'Başarısız', {
          messageType: ToastrMessageType.Error,
          position: ToastrPosition.TopRight,
        });
      }
    }
}

}
