import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { LayoutModule } from './layout/components/layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

// Components
import { HomeComponent } from './modules/home/home.component';
import { SectionSlider4Component } from './modules/home/section-slider4/section-slider4.component';
import { SectionMainNewsComponent } from './modules/home/section-main-news/section-main-news.component';
import { SectionProjects6Component } from './modules/home/section-projects6/section-projects6.component';
import { SectionWhatWeDo2Component } from './modules/home/section-what-we-do2/section-what-we-do2.component';
import { SectionAbout1Component } from './modules/home/section-about1/section-about1.component';
import { SectionWhatWeDo1Component } from './modules/home/section-what-we-do1/section-what-we-do1.component';
import { SectionProjects3Component } from './modules/home/section-projects3/section-projects3.component';
import { SectionServices8Component } from './modules/home/section-services8/section-services8.component';
import { SectionProjects8Component } from './modules/home/section-projects8/section-projects8.component';
import { SectionProjects2Component } from './modules/home/section-projects2/section-projects2.component';
import { SectionServices9Component } from './modules/home/section-services9/section-services9.component';
import { SectionAbout6Component } from './modules/home/section-about6/section-about6.component';
import { SectionVideo1Component } from './modules/home/section-video1/section-video1.component';
import { SectionTestimonials6Component } from './modules/home/section-testimonials6/section-testimonials6.component';
import { SectionPostDetailComponent } from './modules/home/section-post-detail/section-post-detail.component';
import { UnitGridListComponent } from './modules/home/units/unit-grid-list/unit-grid-list.component';
import { SectionInnerBannerComponent } from './layout/components/section-inner-banner/section-inner-banner.component';
import { UnitDetailPageComponent } from './modules/home/units/unit-detail-page/unit-detail-page.component';
import { ProgramListComponent } from './modules/home/program/program-list/program-list.component';
import { ProgramDetailComponent } from './modules/home/program/program-list/program-detail/program-detail.component';
import { ProgramDetailPopupComponent } from './modules/home/program/program-list/program-detail-popup/program-detail-popup.component';
import { ProgramMainListComponent } from './modules/home/program/program-list/program-main-list/program-main-list.component';
import { ProgramMainSubListComponent } from './modules/home/program/program-list/program-main--sub-list/program-main--sub-list.component';
import { SectionPostListComponent } from './modules/home/section-post-list/section-post-list.component';
import { SectionPostCategoryListComponent } from './modules/home/section-post-category-list/section-post-category-list.component';
import { SectionPageCategoryListComponent } from './modules/home/section-page-category-list/section-page-category-list.component';
import { SectionPageDetailComponent } from './modules/home/section-page-detail/section-page-detail.component';
import { SectionEventDetailComponent } from './modules/home/section-event-detail/section-event-detail.component';
import { SectionEventListComponent } from './modules/home/section-event-list/section-event-list.component';
import { SectionEventCategoryListComponent } from './modules/home/section-event-category-list/section-event-category-list.component';
import { SectionNoticeCategoryListComponent } from './modules/home/section-notice-category-list/section-notice-category-list.component';
import { SectionNoticeDetailComponent } from './modules/home/section-notice-detail/section-notice-detail.component';
import { SectionNoticeListComponent } from './modules/home/section-notice-list/section-notice-list.component';
import { SectionSearchListComponent } from './modules/home/section-search-list/section-search-list.component';
import { PageError404Component } from './modules/home/page-error404/page-error404.component';
import { SectionSearchDetailComponent } from './modules/home/section-search-detail/section-search-detail.component';

// Services
import { LangService } from './services/lang-service';
import { LangInterceptor } from './services/lang-interceptor';
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy';
import { HttpErrorInterceptor } from './services/not-found-handler-service';
import { NotFoundHandlerService } from './services/not-found-handller-service';
import { StorageService } from './services/local-storage-serves';
import { PLATFORM_ID, APP_INITIALIZER, InjectionToken } from '@angular/core';
import { LoaderService } from './services/loader-service';
import { LoaderInterceptor } from './services/loader.interceptor';
import { ScrollService } from './services/scrool-services';
import { YouTubeVideoService } from './services/youurbe-services';
import { RehberComponent } from './modules/home/rehber/rehber.component';
import { SectionProtokolComponent } from './modules/home/protokoller/protokoller.compoenent';
import { FormsModule } from '@angular/forms';

export const PLATFORM_ID_TOKEN = new InjectionToken<any>('platform-id');

export function platformInitializer(platformId: any): () => Promise<void> {
  return () => {
    // `platformId`'yi StorageService'e geçir

    return Promise.resolve();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SectionSlider4Component,
    SectionMainNewsComponent,
    SectionProjects6Component,
    SectionWhatWeDo2Component,
    SectionAbout1Component,
    SectionWhatWeDo1Component,
    SectionProjects3Component,
    SectionServices8Component,
    SectionProjects8Component,
    SectionProjects2Component,
    SectionServices9Component,
    SectionAbout6Component,
    SectionVideo1Component,
    SectionTestimonials6Component,
    SectionPostDetailComponent,
    UnitGridListComponent,
    SectionInnerBannerComponent,
    UnitDetailPageComponent,
    ProgramListComponent,
    ProgramDetailComponent,
    ProgramDetailPopupComponent,
    ProgramMainListComponent,
    ProgramMainSubListComponent,
    SectionPostListComponent,
    SectionPostCategoryListComponent,
    SectionPageCategoryListComponent,
    SectionPageDetailComponent,
    SectionEventDetailComponent,
    SectionEventListComponent,
    SectionEventCategoryListComponent,
    SectionNoticeCategoryListComponent,
    SectionNoticeDetailComponent,
    SectionNoticeListComponent,
    SectionSearchListComponent,
    PageError404Component,
    SectionSearchDetailComponent,
    RehberComponent,SectionProtokolComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    LayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          const storageService = new StorageService(PLATFORM_ID_TOKEN);
          return storageService.getData('avesis_accessToken');
        },
        allowedDomains: [
          'abisis.ankara.edu.tr',
          'avesis.ankara.edu.tr',
          'ankara.edu.tr',
        ],
      },
    }),
    ToastrModule.forRoot({
      progressBar: true,
    }),
  ],
  providers: [
    ScrollService,
    YouTubeVideoService,
    LangService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LangInterceptor,
      multi: true
    },
    { provide: PLATFORM_ID_TOKEN, useValue: 'browser' },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: PLATFORM_ID_TOKEN,
      useValue: PLATFORM_ID,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: platformInitializer,
      deps: [PLATFORM_ID],
      multi: true,
    },

    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
