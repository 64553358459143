<div class="page-wraper" *ngIf="!controlAday && !isRehber">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-b30">
            <!-- BLOG START -->
            <div class="blog-post date-style-2 blog-lg" *ngIf="items">
              <div
                class="wt-post-media wt-img-effect zoom-slow"
                *ngIf="items?.manset && items?.banner"
              >
                <a><img src="{{ items?.manset ?? items?.banner }}" alt="" /></a>
              </div>
              <div class="wt-post-info bg-white p-t30">
                <div class="wt-post-title">
                  <h2 class="post-title">{{ items?.baslik }}</h2>
                </div>
                <div
                  class="wt-post-meta"
                  *ngIf="items?.manset && items?.banner"
                >
                  <ul>
                    <li class="post-category">
                      <span>{{ items?.category?.name }}</span>
                    </li>
                    <li class="post-date">
                      {{
                        transform(
                          items.updatedDate != null
                            ? items.updatedDate
                            : items.createdDate
                        )
                      }}
                      {{
                        ayYaz(
                          items.updatedDate != null
                            ? items.updatedDate
                            : items.createdDate
                        )
                      }}
                      {{
                        transformYil(
                          items.updatedDate != null
                            ? items.updatedDate
                            : items.createdDate
                        )
                      }}

                      {{ items.updatedDate != null ? "(Güncellendi)" : "" }}
                    </li>
                  </ul>
                </div>

                <div class="wt-post-text" [innerHTML]="safeContent"></div>
              </div>
            </div>

            <!--  <div class="widget bg-white  widget_tag_cloud">
             <h4 class="tagcloud">Tags</h4>
             <div class="tagcloud">
                 <a routerLink="/blog-list">Analytics</a>

             </div>
         </div> -->

            <!-- BLOG END -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="controlAday" [innerHTML]="safeContent"></div>

<div class="page-wraper" *ngIf="isRehber">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-b30">
            <!-- BLOG START -->
            <div class="blog-post date-style-2 blog-lg">
              <app-rehber></app-rehber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


