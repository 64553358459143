<header
  *ngIf="controlAday"
  class="site-header header-style-4 mobile-sider-drawer-menu"
  [class.active]="isActiveMenu"
  id="mobile-sider-drawer-menu"
>
  <div id="search-toggle-block">
    <div id="search">
      <form
        role="search"
        id="searchform"
        action="arama/search/"
        name="q"
        method="get"
        class="radius-xl"
        (ngSubmit)="search()"
      >
        <div class="input-group">
          <input
            class="form-control"
            value=""
            name="q"
            type="search"
            placeholder="{{
              currentLang == 'tr-TR'
                ? 'Aramak İsteğiniz Kelimeyi Giriniz'
                : 'Search'
            }}"
          />
          <span class="input-group-append"
            ><button type="submit" class="search-btn">
              <i class="fa fa-search"></i></button
          ></span>
        </div>
      </form>
    </div>
  </div>

  <div class="top-bar">
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="wt-topbar-left d-flex flex-wrap align-content-start">
          <ul class="wt-topbar-info e-p-bx text-white">
            <!-- <li>
              <div class="container" id="containersRed">
                <i class="fa fa-microphone"></i> Radyo İlef
              </div>
            </li> -->

            <li>
              <div class="container" id="containers">
                <div id="background">
                  <div class="Circle1"></div>
                  <div class="Circle2"></div>

                  <div class="content">
                    <h1 class="Condition"><i class="fa fa-sun"></i></h1>
                    <h1 class="Temp">
                      <a href="kurumsal/tanitim/kurucumuz-ataturk">
                        Kurucumuz <br />
                        Atatürk</a
                      >
                    </h1>
                    <!--  <h1 class="Time">09:35</h1> -->
                  </div>
                </div>
              </div>
            </li>

            <!-- <li>
              <a href="https://www.facebook.com/" class="fa fa-facebook"></a>
            </li>
            <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
            <li>
              <a href="https://www.linkedin.com/" class="fa fa-linkedin"></a>
            </li> -->

            <i href="#" class="fa fa-bell customFa"></i>
          </ul>

          <div class="d-flex align-items-center">
            <input type="checkbox" id="switch" />

            <div class="switch-btn">
              <label for="switch">
                <div class="icons">
                  <img
                    class="sun-hidden"
                    src="../../../../assets/images/sun.png"
                    alt=""
                    (click)="toggleDarkMode()"
                  />
                  <img
                    class="mon-hidden"
                    src="../../../../assets/images/mon.png"
                    alt=""
                    (click)="toggleDarkMode()"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="wt-topbar-right d-flex flex-wrap align-content-center">
          <ul
            [ngClass]="{ 'd-none': !isMenuVisible }"
            [ngClass]="{ 'd-block': isMenuVisible }"
            class="social-icons"
            *ngIf="headerData"
          >
            <ng-container *ngFor="let topmenu of headerData?.topNav.child">
              <!-- Butonları render et -->
              <li *ngIf="topmenu.button">
                <a target="_blank" href="{{ topmenu.slug }}">
                  <div class="btn btn-outline-light f-sm">
                    <img class="icon-img" src="{{ topmenu.icon }}" alt="" />
                    {{ topmenu.name }}
                  </div>
                </a>
              </li>
            </ng-container>
            <!-- Dropdown menüleri render et -->
            <ng-container *ngFor="let title of displayedDropdownTitles">
              <li>
                <div class="dropdown" style="
                top: 1vh;
            ">
                  <button
                    class="btn btn-outline-light f-sm dropdown-toggle"
                    type="button"
                    [attr.id]="'dropdownMenuButton' + title"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ title }}
                  </button>
                  <div
                    class="dropdown-menu"
                    [attr.aria-labelledby]="'dropdownMenuButton' + title"
                  >
                    <ng-container *ngFor="let submenu of dropdownItems[title]">
                      <a
                        class="dropdown-item"
                        target="_blank"
                        href="{{ submenu.slug }}"
                      >
                        <img class="icon-img" src="{{ submenu.icon }}" alt="" />
                        {{ submenu.name }}
                      </a>
                    </ng-container>
                  </div>
                </div>
              </li>
            </ng-container>
            <!-- Modal -->
            <li>
              <a
                target="_blank"
                href="/rehber"
              >
                <div class="btn btn-outline-light f-sm">

                  <i class="fa fa-address-book icon-fa"></i> Rehber
                </div>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://mezun.ankara.edu.tr/auth/login">
                <div class="btn btn-outline-light f-sm">
                  <i class="fa fa-graduation-cap icon-fa"></i
                  >{{ currentLang == "tr-TR" ? "Mezun" : "Graduate" }}
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.ilef.ankara.edu.tr/radyoilef/"
              >
                <div class="btn btn-outline-light f-sm">
                  <i class="fa fa-microphone icon-fa"></i> Radyo İlef
                </div>
              </a>
            </li>



          </ul>

          <ul class="wt-topbar-info-2 e-p-bx text-white">
            <li class=" ">
              <mat-select
                class="btn btn-light"
                style="max-width: 165px; padding: 4px"
                [value]="selectedValue"
                (selectionChange)="selectSite($event)"
              >
                <mat-select-trigger>
                  <img width="25" src="{{ icon }}" />
                  {{ selectedName }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let gender of languages"
                  [value]="gender.val"
                  [disabled]="isDisabled(gender.val)"
                >
                  <img
                    src="{{ gender.src }}"
                    alt=""
                    class="p-1"
                    height="auto"
                    width="25"
                  />

                  {{ gender.name }}
                </mat-option>
              </mat-select>
            </li>
          </ul>

          <ul class="wt-topbar-info-2 e-p-bx text-white mobile-menu-button">
            <li class="" (click)="toggletopMenu()">
              <a class="btn btn-outline-light">
                <i class="fa fa-chevron-circle-down"> </i>Hızlı Menü</a
              >
            </li>
          </ul>

          <div
            class="wt-topbar-right d-flex flex-wrap align-content-center btn mobilex"
          >
            <div class="header-search">
              <a
                href="javascript:void(0);"
                (click)="togglePosition()"
                class="header-search-icon"
                ><i class="fa fa-search"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="sticky-header main-bar-wraper navbar-expand-lg"
    *ngIf="headerData"
  >
    <div
      class="main-bar"
      [ngStyle]="isScrolled ? { top: '0px' } : { top: '62px' }"
      #stickyHeader
    >
      <div class="container-fluid clearfix">
        <div class="logo-header">
          <div class="logo-header-inner logo-header-one">
            <div class="row">
              <div class="col-md-3 col-2 col-sm-2">
                <a routerLink="/">
                  <img
                    id="headLogoId2"
                    src="{{ headerData?.logo }}"
                    alt="Ankara Üniversitesi"
                  />
                </a>
              </div>
              <div class="col-md-9 col-7 col-sm-8 d-md-block">
                <div class="row">
                  <div id="container2">
                    <p class="name-title">
                      {{
                        currentLang == "tr-TR"
                          ? "Ankara Üniversitesi"
                          : "Ankara University"
                      }}
                    </p>
                  </div>

                  <div class="">
                    <div id="container">
                      <div id="flip ">
                        <!--     <div>
                          <div>
                            {{
                              currentLang == "tr-TR"
                                ? "Araştırma Üniversitesi"
                                : "Research University"
                            }}
                          </div>
                        </div> -->

                        <div>
                          <div class="sub-title">
                            <p
                              class="animated-text"
                              [class.hidden]="currentText !== 1"
                              id="text1"
                            >
                              {{
                                currentLang === "tr-TR"
                                  ? "Cumhuriyetin İlk Üniversitesi"
                                  : "Republic's First University"
                              }}
                            </p>
                            <p
                              class="animated-text"
                              [class.hidden]="currentText !== 2"
                              id="text2"
                            >
                              Devletin Fikir Mutfağı
                            </p>

                            <p
                              class="animated-text"
                              [class.hidden]="currentText !== 3"
                              id="text3"
                            >
                              Araştırma Üniversitesi
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-1 col-sm-2">
                <button
                  id="mobile-side-drawer"
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  class="navbar-toggler collapsed"
                  (click)="toggleSideDrawer()"
                >
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar icon-bar-first"></span>
                  <span class="icon-bar icon-bar-two"></span>
                  <span class="icon-bar icon-bar-three"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- NAV Toggle Button -->

        <!-- MAIN Vav -->
        <div
          class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-end"
        >
          <ul class="nav navbar-nav" *ngIf="navData != null">
            <li
              *ngFor="let navChild of navData?.child"
              [class.has-child]="isMobile || hasChildren(navChild)"
              [class.nav-active]="isActive(navChild)"
              [class.has-child]="isMobile || hasChildren(navChild)"
              (mouseenter)="showSubMenu(navChild)"
              (mouseleave)="hideSubMenu(navChild)"
            >
              <a href="{{ navChild.url }}">{{ navChild.menuName }}</a>
              <div
                *ngIf="hasChildren(navChild)"
                class="fa fa-angle-right submenu-toogle"
                (click)="toggleMenu(navChild)"
              ></div>
              <ul
                class="sub-menu"
                *ngIf="isActive(navChild) || hasChildren(navChild)"
              >
                <li
                  *ngFor="let nav of navChild.child"
                  [class.has-child]="isMobile || hasChildren(nav)"
                  [class.nav-active]="isActive(nav)"
                >
                  <a href="{{ nav.url }}">{{ nav.menuName }}</a>
                  <div
                    *ngIf="hasChildren(nav)"
                    class="fa fa-angle-right submenu-toogle"
                    (click)="toggleMenu(nav)"
                  ></div>
                  <ul
                    class="sub-menu"
                    *ngIf="isActive(nav) || hasChildren(nav)"
                  >
                    <li
                      *ngFor="let nav1 of nav.child"
                      [class.has-child]="hasChildren(nav1)"
                      [class.nav-active]="isActive(nav1)"
                    >
                      <a href="{{ nav1.url }}">{{ nav1.menuName }}</a>
                      <div
                        *ngIf="hasChildren(nav1)"
                        class="fa fa-angle-right submenu-toogle"
                        (click)="toggleMenu(nav1)"
                      ></div>
                      <ul
                        class="sub-menu"
                        *ngIf="isActive(nav1) || hasChildren(nav)"
                      >
                        <li
                          *ngFor="let nav2 of nav1.child"
                          [class.has-child]="hasChildren(nav2)"
                          [class.nav-active]="isActive(nav2)"
                        >
                          <a href="{{ nav2.url }}">{{ nav2.menuName }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

    <!--       <ul
            class="social-icons wt-social-links footer-social-icon d-md-none d-social"
          >
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/ankara.uni"
                class="fa fa-instagram"
              ></a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/AnkaraUniversitesi"
                class="fa fa-facebook"
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/ankarauni"
                class="fa fa-twitter"
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.youtube.com/ankarauni"
                class="fa fa-youtube"
              ></a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</header>
<app-section-welcome1
  *ngIf="controlHome && controlAday"
  [data]="headerData?.shortCutNav"
  class="sectioncustom"
></app-section-welcome1>

<div
  *ngIf="controlAday"
  class="modal fade"
  id="exampleModalXl"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalXlLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-lg">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalXlLabel">
          Çözüm Merkezi Destek Talebi Oluştur
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="iframe-container">
          <iframe src="https://crm.ankara.edu.tr/crm/destektalebi/5"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngFor="let pop of popup">
  <!-- Modal -->
  <div
    class="modal fade show"
    id="myModal1"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel1"
    aria-hidden="true"
    style="display: block; background: rgba(0, 0, 0, 0.5)"
    *ngIf="modalStatus && !isMobile && pop.type == true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel1"></h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <a href="{{pop.url}}" target="_blank">
          <img
            src="{{ pop.image }}"
            alt=""
            *ngIf="!isMobile && pop.type == true"
          />
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade show"
    id="myModal1"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel1"
    aria-hidden="true"
    style="display: block; background: rgba(0, 0, 0, 0.5)"
    *ngIf="modalStatus && isMobile && pop.type == false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel1"></h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <a href="{{pop.url}}" target="_blank">
          <img
            src="{{ pop.image }}"
            alt=""
            *ngIf="isMobile && pop.type == false"
          />
        </a>
        </div>
      </div>
    </div>
  </div>
</div>
