import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SectionHeader4Component } from './header/section-header4/section-header4.component';
import { SectionFooter1Component } from './footer/section-footer1/section-footer1.component';
import { LoaderComponent } from 'src/app/elements/loader/loader.component';
import { LoaderDirective } from 'src/app/directives/loader.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { SectionWelcome1Component } from 'src/app/modules/home/section-welcome1/section-welcome1.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,

  ],
  declarations: [
    SectionHeader4Component,
    SectionFooter1Component,
    SectionWelcome1Component,

    LoaderComponent,
    LoaderDirective,
  ],
  exports: [
    SectionHeader4Component,
    SectionFooter1Component,
    SectionWelcome1Component,
    LoaderComponent,
    LoaderDirective,

  ],
})
export class LayoutModule {}
