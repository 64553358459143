<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <!-- BLOG SECTION START -->
        <div class="section-content" *ngIf="newsData != null">
          <div class="row d-flex justify-content-center">
            <div class="{{ newsData.categories!=null && currentLang=='tr-TR'?' col-xl-12 col-lg-12 col-md-12 col-sm-12':' col-xl-12 col-lg-12 col-md-12 col-sm-12'}}">
              <!--Block one-->

              <div class="row" *ngIf="newsData.items != null">
                <div
                  class="blog-post date-style-2  {{ newsData.categories!=null && currentLang=='tr-TR'?' col-md-3':' col-md-3'}}"
                  *ngFor="let item of newsData.items"
                >
                  <div class="wt-post-media wt-img-effect zoom-slow">


                  <a
                  href="/{{item.url}}"

                  ><img src="{{ item.manset ?? item.banner }}" alt=""
                  /></a
                >


                  </div>

                  <div class="wt-post-info bg-white p-t10">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-category">


                          <span *ngIf="item.tur=='haber'" class="danger-bg">{{ item.tur }}  </span>
                          <span *ngIf="item.tur=='duyuru'" class="primary-bg">{{ item.tur }}  </span>
                          <span *ngIf="item.tur=='sayfa'" class="success-bg">{{ item.tur }}  </span>
                          <span *ngIf="item.tur=='etkinlik'" class="warning-bg">{{ item.tur }}  </span>
                        </li>
                      </ul>
                    </div>
                    <div class="wt-post-title">
                      <p class="post-title">




                      <a
                      routerLink="/{{item.url}}"
                      class="site-text-secondry"
                      >{{ item.baslik }}  </a
                    >




                      </p>
                      <div class="wt-post-readmore"></div>
                      <div class="row justify-content-around" >
                        <div class="col-md-6">

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="pagination m-b0 p-b0" *ngIf="newsData != null">
                <li class="page-item" *ngIf="newsData.hasPrevious">
                  <a class="page-link" href="javascript:void(0);">]</a>{{sonraki}}
                </li>
                <li class="page-item" *ngFor="let page of pagesArray">


                      <a
                      href="/arama/{{aranan}}/{{page}}"
                      class="page-link"
                      >{{ page }}  </a
                    >





                </li>
                <li class="page-item" *ngIf="newsData.hasNext">
                  <a class="page-link" href="javascript:void(0);">{{sonraki}}</a>
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
