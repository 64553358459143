import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDetailListBase } from 'src/app/contracts/base/detail-base';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { CategoryEntity } from 'src/app/contracts/cms/category-entity';

import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';

interface UrlData {
  type: string;
  getMethod: string;
}
const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const urlData: UrlData[] | any[] = [
  { type: 'haberler', getMethod: 'News' },
  { type: 'etkinlikler', getMethod: 'Events' },
  { type: 'duyurular', getMethod: 'Notices' },
  { type: 'kurumsal', getMethod: 'Pages' },
];

@Component({
  selector: 'app-section-event-list',
  templateUrl: './section-event-list.component.html',
  styleUrls: ['./section-event-list.component.css'],
})
export class SectionEventListComponent implements OnInit {
  postType: string | any;

  categorySlug: string | any;

  newsData: IDetailListBase<NewsEntity[]> | any;
  method: string | any;
  page = 1;
  controlPage = 0;

  currentLang = 'tr-TR';
  getlang = 'primary';
  breadcumb: Breadcrumb[] = [];
  onceki = '';
  sonraki = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private seoService: SeoService,
  ) {
    this.postType = this.activatedRoute.snapshot.params['type'];
    this.categorySlug = this.activatedRoute.snapshot.params['category'];

    const parsedNumber = parseInt(this.categorySlug); // deger'i bir tamsayıya dönüştürür

    if (this.activatedRoute.snapshot.params['page']) {
      this.page = this.activatedRoute.snapshot.params['page'];
      this.controlPage = this.activatedRoute.snapshot.params['page'];
    }
  }
  pagesArray: number[] = [];

  async ngOnInit() {

    window.scrollTo(0, 0);
    if (!this.page) {
      this.page = 0;
    }

    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });
  }
  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    await this.getNews();

    this.updateMetaTags();

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);
  }

  updateMetaTags(): void {
    const title =
      this.currentLang == 'tr-TR'
        ? 'Etkinlikler | Ankara Üniversitesi'
        : 'Events | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'etkinlikler', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/etkinlikler/' + this.page;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      'Etkinlikler',
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );
    this.breadcumb.push({
      label: "Etkinlikler",
      url: '/kategori/'+canonicalUrl,
    });
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  routerw(val: string) {
    this.newsData = null;

    this.page = 1;
    this.categorySlug = val;
    this.router.navigate(['/kategori', this.postType, val]);
    this.getNews();
  }

  async getNews() {
    const promiseData: IDetailListBase<NewsEntity[]> | any =
      await this.httpClientService.getPageable(
        this.page - 1,
        20,
        'CMS',
        'GetMainevents',
        '',
        1,
        this.currentLang
      );

    this.newsData = promiseData;
    this.pagesArray = Array.from(
      { length: this.newsData.pages },
      (_, index) => index + 1
    );
    this.limitPages(20);
  }

  limitPages(limit: number) {
    this.pagesArray = this.pagesArray.slice(0, limit);
  }
  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    const lang = this.currentLang;
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }
}
